import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import auth from '../services/authService';

interface ProtectedRouteProps {
  element: React.ReactElement;
  [key: string]: any; // To allow any additional props
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  ...rest
}) => {
  const location = useLocation();
  const params = useParams();

  if (!auth.getCurrentUser()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return React.cloneElement(element, { ...rest, params, location });
};

export default ProtectedRoute;
