import React from 'react';

import SetupFlow from './setupFlow/index';
//import SetVolume from "./setVolume";
import Welcome from './setupFlow/steps/Welcome';
import LevelCheck from './setupFlow/steps/LevelCheck';
import HeadphoneCheck from './setupFlow/steps/HeadphoneCheck';
import MicrophoneCheck from './setupFlow/steps/MicrophoneCheck';
import Finish from './setupFlow/steps/Finish';

interface PretestChecksProps {
  onComplete: () => void;
  levelCheckAudio: string;
}

const PretestChecks: React.FC<PretestChecksProps> = ({
  onComplete,
  levelCheckAudio,
}) => (
  <SetupFlow completedAudio="/audio/ding.mp3" onComplete={onComplete}>
    <Welcome />
    <LevelCheck audio={levelCheckAudio} />

    <HeadphoneCheck
      gapBetween={2000}
      leftAudio="/audio/audiocheck.net_L.mp3"
      rightAudio="/audio/audiocheck.net_R.mp3"
    ></HeadphoneCheck>

    <MicrophoneCheck
      recordingLength={4000}
      audio="/audio/tone.mp3"
      timeBeforeBeep={1000}
    ></MicrophoneCheck>

    <Finish />
  </SetupFlow>
);

export default PretestChecks;
