import React from 'react';
import { BottomBar } from '../../setupFlow/helpers';

export const Page = (props) => {
  console.log('xxx', props);
  return (
    <React.Fragment>
      <div dangerouslySetInnerHTML={props.content} />
      <BottomBar {...props}>Next Page</BottomBar>
    </React.Fragment>
  );
};
