import React from 'react';
import { BottomBar } from '../helpers';

export const Finish = (props) => {
  return (
    <>
      <h1>You&apos;re ready to start</h1>
      <p>Everything looks good and you can now start the experiment.</p>
      <BottomBar noback={true} {...props}>
        Proceed to experiment
      </BottomBar>
    </>
  );
};

Finish.skipCount = true;

export default Finish;
