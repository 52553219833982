import React, { Component } from 'react';
import auth from '../services/authService';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

class Logout extends Component {
  doCancelLogout = (): void => {
    window.location.href = '/welcome';
  };

  doLogout = (): void => {
    auth.logout();
    window.location.href = '/';
  };

  render(): React.JSX.Element | null {
    return (
      <Container>
        <h1>WARNING! ABOUT TO LOGOUT OUT! WARNING!</h1>
        <Modal show={true} onHide={this.doCancelLogout} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Logout</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to logout?</Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={this.doLogout}>
              Yes. Please logout.
            </Button>
            <Link to="/welcome" className="btn btn-primary">
              No. Please continue.
            </Link>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default Logout;
