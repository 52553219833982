import React from 'react';
import { Navigate, useLocation, useNavigate, Link } from 'react-router-dom';
import Joi from 'joi-browser';
import Form from './common/form';
import auth from '../services/authService';
import Container from 'react-bootstrap/Container';

interface LoginFormState {
  data: {
    username: string;
    password: string;
  };
  errors: {
    username?: string;
    password?: string;
  };
}

function LoginFormWrapper(props) {
  const location = useLocation();
  const navigate = useNavigate();
  return <LoginForm {...props} location={location} navigate={navigate} />;
}

class LoginForm extends Form {
  state: LoginFormState = {
    data: { username: '', password: '' },
    errors: {},
  };

  schema = {
    username: Joi.string().required().label('Username'),
    password: Joi.string().required().label('Password'),
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;
      await auth.login(data.username, data.password);
      const { state } = this.props.location;
      this.props.navigate(state ? state.from.pathname : '/');
      window.location = state ? state.from.pathname : '/';
    } catch (ex: any) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    if (auth.getCurrentUser()) return <Navigate replace={true} to="/" />;
    return (
      <Container>
        <h1>Login</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput('username', 'Username')}
          {this.renderInput('password', 'Password', 'password')}
          {this.renderButton('Login')}
        </form>
        <br />
        <i>
          Forgot your password? <Link to="/forgot">Click to reset</Link>
        </i>
      </Container>
    );
  }
}

export default LoginFormWrapper;
