import http from './httpService';

interface Owner {
  email: string;
}

interface Track {
  _id: string;
  listName: string;
  filename?: string;
  uploadDate?: string;
  metadata: {
    owner: string;
    blobURL: string;
  };
}

async function fetchTracksOld(
  trackList: Track[],
  filter: ((__trackList: Track[]) => { data: Track[] }) | null,
  setState: (__state: { recordings: Track[] }) => void
): Promise<void> {
  const tracks = filter ? filter(trackList).data : trackList;
  if (!tracks) return;

  for (const track of tracks) {
    console.log('fetch Track');
    if (track.metadata.blobURL)
      // CHECK: Is this necessary
      URL.revokeObjectURL(track.metadata.blobURL);

    const res = await http.get(`/recordings/${track.listName}/${track._id}`, {
      responseType: 'arraybuffer',
    });
    const blob = new Blob([res.data], {
      type: 'audio/mp3',
    });
    track.metadata.blobURL = URL.createObjectURL(blob);

    setState({
      recordings: trackList,
    });
    console.log('completed fetch Track');
  }
}

async function fetchTracks(
  trackList: Track[],
  filter: ((__trackList: Track[]) => { data: Track[] }) | null,
  onProgress: (progress: number) => void
): Promise<Track[]> {
  const tracks = filter ? filter(trackList).data : trackList;
  if (!tracks) return [];

  // Array to hold updated tracks with blob URLs
  const updatedTracks = [];

  for (let i = 0; i < tracks.length; i++) {
    const track = tracks[i];
    // console.log('Fetching Track:', track._id);

    // Revoke existing blob URL if present
    if (track.metadata.blobURL) {
      URL.revokeObjectURL(track.metadata.blobURL);
    }

    try {
      // Fetch audio data as arraybuffer
      const res = await http.get(`/recordings/${track.listName}/${track._id}`, {
        responseType: 'arraybuffer',
      });

      // Create a new blob URL for the audio data
      const blob = new Blob([res.data], { type: 'audio/mp3' });
      track.metadata.blobURL = URL.createObjectURL(blob);

      // Add updated track to the list
      updatedTracks.push(track);
      // console.log('Completed fetching Track:', track._id);
      // Update progress
      onProgress(i + 1);
    } catch (error) {
      console.error(`Failed to fetch track ${track._id}:`, error);
    }
  }
  // console.log('All tracks fetched');

  return updatedTracks;
}

async function deleteTrack(track: Track): Promise<void> {
  console.log(track);
  return await http.delete(`/recordings/${track.listName}/${track._id}`);
}

async function fetchTrackList(
  listName: string,
  owner: Owner | null = null
): Promise<Track[]> {
  let { data: trackList } = await http.get(`/recordings/${listName}`);
  for (const track of trackList) {
    track.listName = listName;
  }
  console.log('owner', owner);
  if (owner)
    trackList = trackList.filter(
      (r: Track) => r.metadata.owner === owner.email
    );
  return trackList;
}

function saveTrack(
  trackListName: string,
  owner: Owner,
  filename: string,
  file: string
): void {
  const bodyFormData = new FormData();
  bodyFormData.set('bucketname', trackListName);

  bodyFormData.append('owner', owner ? owner.email : 'Anonymous');
  bodyFormData.append('name', filename);
  bodyFormData.append('audio', file);
  http.post('/recordings', bodyFormData);
}

export default {
  fetchTracks,
  deleteTrack,
  fetchTrackList,
  saveTrack,
};
