import http from './httpService';

const apiEndpoint = '/tutorials';

export async function getTutorial(tutorialName: string): Promise<any> {
  console.log('getTutorial');
  try {
    const pages = await http.get(`${apiEndpoint}/${tutorialName}`);
    return pages;
  } catch (error) {
    console.error('Error fetching tutorial:', error);
    return null;
  }
}
