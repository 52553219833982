import http from './httpService';

const apiEndpoint = '/messages';

interface ApiResponse {
  data: any; // Replace `any` with the actual response type if known
}

export function send(text: string): Promise<ApiResponse> {
  console.log('sendMessage');
  return http.post(`${apiEndpoint}/`, {
    message: text,
  });
}

export default {
  send,
};
