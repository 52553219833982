import React, { Component, ChangeEvent } from 'react';
import Input from './input';
import Select from './select';
import Joi, { ValidationError } from 'joi-browser';

interface FormState {
  data: Record<string, any>;
  errors: Record<string, string>;
}

class Form extends Component<unknown, FormState> {
  state: FormState = {
    data: {},
    errors: {},
  };

  schema: Record<string, Joi.Schema> = {}; // Schema to be defined in subclasses

  validate = (): Record<string, string> | null => {
    const options = { abortEarly: false };
    const { error }: { error?: ValidationError } = Joi.validate(
      this.state.data,
      this.schema,
      options
    );
    if (!error) return null;

    const errors: Record<string, string> = {};
    for (const item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({
    name,
    value,
  }: {
    name: string;
    value: string;
  }): string | null => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error }: { error?: ValidationError } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const input = e.currentTarget;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  renderButton(label: string) {
    return (
      <button disabled={!!this.validate()} className="btn btn-primary mt-4">
        {label}
      </button>
    );
  }

  renderInput(
    name: string,
    label: string,
    type = 'text',
    autocomplete = 'no',
    multiple?: boolean,
    ...rest: any[]
  ): React.JSX.Element {
    const { data, errors } = this.state;
    return (
      <Input
        {...rest}
        type={type}
        name={name}
        value={data[name]}
        label={label}
        autoComplete={autocomplete}
        onChange={this.handleChange}
        error={errors[name]}
        multiple={multiple}
      />
    );
  }

  renderSelect(
    name: string,
    label: string,
    options: { value: string; label: string }[]
  ): React.JSX.Element {
    const { data, errors } = this.state;

    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  doSubmit = () => {
    // This method should be overridden in the subclass
    console.error('doSubmit method not implemented');
  };
}

export default Form;
