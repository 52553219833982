import http from './httpService';

const apiEndpoint = '/tests';

interface Test {
  name: string;
}

function testUrl(testName: string): string {
  return `${apiEndpoint}/test/${testName}`;
}

export async function getMyTests(): Promise<any> {
  const { data } = await http.get(`${apiEndpoint}/mytests`);

  return data;
}

export async function getMyTestRecords(): Promise<any> {
  const { data } = await http.get(`${apiEndpoint}/mytestrecords`);
  return data;
}

export async function getTestRecordsForUser(userName: string): Promise<any> {
  const { data } = await http.get(`${apiEndpoint}/usertestrecords/${userName}`);
  return data;
}

export async function getTest(testName: string): Promise<any> {
  console.log('getTest', testName);
  const test = await http.get(testUrl(testName));
  console.log(test.data);
  return test.data;
}

export function updateTestRecord(test: Test, nComplete: number): Promise<any> {
  // TODO - this should update the user record for the given test
  console.log('xxx in updateTestRecord save west', test, nComplete);
  return http.post(`${apiEndpoint}/updaterecord`, {
    name: test.name,
    nComplete: nComplete,
  });
}

//export function updateTestRecord(test, nComplete) {
// TODO - this should update the user record for the given test
//    console.log('xxx saveText', test, nComplete)
//    if (test._id) {
//        const body = {
//            ...test
//        };
//        delete body._id;
//        return http.put(testUrl(test.name), body);
//    } else {
//        return http.post(apiEndpoint, test);
//    }
//}

export async function deleteTest(testName: string): Promise<void> {
  return await http.delete(testUrl(testName));
}
