import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import NavBar from './components/navBar';
import Container from 'react-bootstrap/Container';
import ProtectedRoute from './components/protectedRoute';
import auth from './services/authService';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

// Import components
import About from './components/about';
import Home from './components/home';
import ListeningTest from './components/listeningTest';
import LoginForm from './components/loginForm';
import Logout from './components/logout';
import ForgotPassword from './components/forgotPassword';
import ResetPassword from './components/resetPassword';
import ContactForm from './components/contactForm';
import ManageUsers from './components/manageUsers';
import RecordStimuli from './components/recorder';
import RegisterForm from './components/registerForm';
import PretestChecks from './components/pretestChecks';
import Welcome from './components/welcome';
import TestTable from './components/testTable';
import Tracks from './components/tracks';
import UploadStimuli from './components/uploadStimuli';
import UploadTest from './components/uploadTest';

class App extends React.Component {
  state = {};

  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }

  render() {
    const { user } = this.state;

    // Regular users have large fonts and centered style
    let fontsize = '1.6rem';
    let topstyle = 'my-center';

    // Admin user is styled differently
    if (user && user.isAdmin) {
      fontsize = '1.0rem';
      topstyle = '';
    }

    const protectedRoutes = [
      { path: '/runTest/:testName', element: <ListeningTest /> },
      { path: '/runTest', element: <PretestChecks /> },
      { path: '/welcome', element: <Welcome /> },
      { path: '/contactus', element: <ContactForm /> },
      { path: '/record', element: <RecordStimuli /> },
      { path: '/review/tests', element: <TestTable /> },
      { path: '/review/:listName', element: <Tracks /> },
      { path: '/upload', element: <UploadStimuli /> },
      { path: '/uploadTest', element: <UploadTest /> },
      { path: '/users', element: <ManageUsers /> },
    ];

    return (
      <>
        <ToastContainer />
        <NavBar user={user} />
        <Container style={{ fontSize: fontsize }} className={topstyle}>
          <Routes>
            {protectedRoutes.map(({ path, element }) => (
              <Route
                key={path}
                path={path}
                element={<ProtectedRoute user={user} element={element} />}
              />
            ))}
            <Route path="/about" element={<About />} />
            <Route path="/register" element={<RegisterForm />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/forgot" element={<ForgotPassword />} />
            <Route path="/reset/:id" element={<ResetPassword />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Navigate to="/not-found" />} />
          </Routes>
        </Container>
      </>
    );
  }
}

export default App;
