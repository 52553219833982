import React from 'react';
import Joi from 'joi-browser';
import Form from './common/form';
import * as userService from '../services/userService';
import auth from '../services/authService';
import Container from 'react-bootstrap/Container';

interface RegisterFormState {
  data: {
    username: string;
    password: string;
    name: string;
  };
  errors: Record<string, string>;
}

class RegisterForm extends Form {
  state: RegisterFormState = {
    data: { username: '', password: '', name: '' },
    errors: {},
  };

  schema = {
    username: Joi.string().required().email().label('Username'),
    password: Joi.string().required().min(5).label('Password'),
    name: Joi.string().required().label('Name'),
  };

  doSubmit = async (): Promise<void> => {
    try {
      const response = await userService.register(this.state.data);
      auth.loginWithJwt(response.headers['x-auth-token']);
      window.location.href = '/';
    } catch (ex: any) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render(): React.JSX.Element {
    return (
      <Container>
        <h1>Register</h1>

        <div className="alert alert-primary" role="alert">
          <ul>
            <li>Please do not re-use existing passwords.</li>
            <li>
              The email address is requested so that we can send you a reset
              link if you forget your password.
            </li>
          </ul>
        </div>

        <form onSubmit={this.handleSubmit}>
          {this.renderInput('name', 'First Name', 'name')}
          {this.renderInput('username', 'Email Address')}
          {this.renderInput('password', 'Password', 'password', 'new-password')}
          {this.renderButton('Register')}
        </form>
      </Container>
    );
  }
}

export default RegisterForm;
