import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';

export const BottomBar = ({
  children,
  success,
  back,
  flowStage,
  noback,
  __flowTotal,
}) => {
  return (
    <>
      <ButtonGroup>
        {flowStage > 0 && !noback && (
          <Button variant="secondary" onClick={back}>
            Back
          </Button>
        )}
        <Button onClick={success}>{children}</Button>
      </ButtonGroup>
    </>
  );
};
