import React, { Component } from 'react';

import Upload from './common/upload/upload/Upload';

class UploadTest extends Component {
  doUpload = (req: XMLHttpRequest, file: File): void => {
    const fr = new FileReader();

    fr.addEventListener('load', (__e) => {
      const data = JSON.parse(fr.result as string);
      req.open('POST', `${process.env.REACT_APP_API_URL}/tests`);
      req.setRequestHeader('Content-type', 'application/json');
      req.send(JSON.stringify(data));
    });

    fr.readAsText(file);
  };

  render(): React.JSX.Element {
    return (
      <Upload
        doUpload={this.doUpload}
        title="Upload JSON test description files"
      />
    );
  }
}

export default UploadTest;
