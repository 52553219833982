import React from 'react';
import auth from '../../../services/authService';

import Row from 'react-bootstrap/Row';
import { BottomBar } from '../helpers';
import RangeSlider from 'react-bootstrap-range-slider';
import Container from 'react-bootstrap/Container';
import { useVolume } from '../../hooks/useVolume';
import ReactHowler from 'react-howler';

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

const LevelCheck = (props) => {
  // This is a custom hook that remembers the user's volume setting. If setVolume is called the updated version
  // will be saved. "useVolume" can be called from any component
  const [volume, setVolume] = useVolume(auth.getCurrentUser());

  return (
    <div>
      <h1>Adjusting the volume</h1>
      If necessary, use the controls to set the volume to a comfortable level.
      <p>The current level is</p>
      <h2>{volume}</h2>
      <Container style={{ maxWidth: '100%' }}>
        <Row>
          <RangeSlider
            style={{ width: '60%' }}
            value={volume}
            step={1}
            max={100}
            min={0}
            tooltip="off" // show, hide or always - hiding because it doesnt appear in correct place
            onChange={(changeEvent) => setVolume(changeEvent.target.value)}
            // orientation="horizontal"
          />
        </Row>
        <p />
      </Container>
      <ReactHowler
        volume={volume / 100}
        preload={true}
        loop={true}
        format={['mp3', 'aac']}
        src={props.audio}
      />
      <BottomBar noback={true} {...props}>
        Accept
      </BottomBar>
    </div>
  );
};

export default LevelCheck;
