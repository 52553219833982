import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Home extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col sm={9}>
            <Image width={280} src="/clarity_logo.png" />
            <p></p>
            <h1 className="display-4">Listen@Home</h1>
            <p className="lead">An app for home-based listening tests.</p>
          </Col>
        </Row>

        <p></p>

        <Button href="/welcome" variant="secondary">
          Start
        </Button>
      </Container>
    );
  }
}

export default Home;
