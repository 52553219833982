import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

const About: React.FC = () => {
  return (
    <Container>
      <h1>Clarity Listen@Home Prototype</h1>
      This is an internal prototype website for testing the Listen@Home
      client-server and database architecture. The final version made available
      to end-users will only expose the listening test functionality.
      <Row className="my-3">
        <Col>
          <Card border="primary">
            <Card.Body>
              <Card.Title>Register to use</Card.Title>
              <Card.Text>
                You must register and login before you can try the demo. (Note,
                you do not need to provide a real email address).
              </Card.Text>
              <Button href="/register" variant="primary">
                Register
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card border="primary">
            <Card.Body>
              <Card.Title>Create stimuli</Card.Title>
              <Card.Text>
                Once registered you can create a list on stimuli by recording
                them yourself or by uploading existing audio files.
              </Card.Text>
              <Button className="mx-3" href="/record" variant="primary">
                Record
              </Button>
              <Button href="/upload" variant="primary">
                Upload
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="my-3">
        <Col>
          <Card border="primary">
            <Card.Body>
              <Card.Title>Run a listening test</Card.Title>
              <Card.Text>
                Once stimuli have been uploaded you can listen to them and speak
                your responses.
              </Card.Text>
              <Button href="/listen" variant="primary">
                Listen
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card border="primary">
            <Card.Body>
              <Card.Title>Review your results</Card.Title>
              <Card.Text>
                You can review the stimuli and responses that have been stored
                in the database.
              </Card.Text>
              <ButtonToolbar>
                <Button
                  className="mx-3"
                  href="/review/stimuli"
                  variant="primary"
                >
                  Stimuli
                </Button>
                <Button href="/review/response" variant="primary">
                  Responses
                </Button>
              </ButtonToolbar>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
