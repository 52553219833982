import React, { Component } from 'react';

import { getTutorial } from '../../services/tutorialService';

import { Page } from '.';

import SetupFlow from '../setupFlow';

class Tutorial extends Component {
  state = {
    pages: '',
  };

  onComplete = this.props.onComplete;

  async componentDidMount() {
    const res = await getTutorial('main');
    console.log('res', res);
    if (res) this.setState({ pages: res.data.pages });
    else this.setState({ pages: [['<h1>No tutorial available</h1>']] });
  }

  render() {
    let pages = this.state.pages;
    let content;
    console.log('in tutorial render');

    if (pages === '') content = <div></div>;
    else {
      pages = pages.map((page, index) => {
        return { __html: page, key: index };
      });

      content = (
        <SetupFlow
          completedAudio="/audio/click.wav"
          onComplete={this.onComplete}
        >
          {pages.map((page, index) => {
            return <Page key={index} content={page}></Page>;
          })}
        </SetupFlow>
      );
    }
    return <div>{content}</div>;
  }
}
export default Tutorial;
