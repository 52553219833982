import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';

import Play from './common/play';

import Table from './common/table';
import Pagination from './common/pagination';

import auth from '../services/authService';
import audio from '../services/audioService';
import { paginate } from '../utils/paginate';

interface Track {
  _id: string;
  listName: string;
  filename?: string;
  uploadDate?: string;
  metadata: {
    owner: string;
    blobURL: string;
  };
  [key: string]: any;
}

interface SortColumn {
  path: string;
  order: 'asc' | 'desc';
}

interface TracksState {
  recordings: Track[];
  user: any;
  sortColumn: SortColumn;
  currentPage: number;
  pageSize: number;
  loading: boolean;
}

interface TracksProps {
  match: {
    params: {
      listName: string;
    };
  };
}

class Tracks extends Component<TracksProps, TracksState> {
  state: TracksState = {
    recordings: [],
    user: null,
    sortColumn: { path: 'name', order: 'asc' },
    currentPage: 1,
    pageSize: 6,
    loading: true,
  };

  columns = [
    {
      path: 'filename',
      label: 'name',
    },
    { path: 'uploadDate', label: 'uploadDate' },
    { path: 'metadata.owner', label: 'owner' },
    {
      key: 'play_button',
      label: '',
      content: (data: Track) => {
        return data.metadata.blobURL ? (
          <Play url={data.metadata.blobURL}> </Play>
        ) : (
          ' '
        );
      },
    },
  ];

  deleteColumn = {
    key: 'delete',
    label: 'Delete',
    content: (track: Track) => (
      <Button
        variant="danger"
        onClick={() => {
          this.handleDelete(track);
        }}
      >
        Delete
      </Button>
    ),
  };

  constructor(props: TracksProps) {
    super(props);
    const user = auth.getCurrentUser();

    if (user?.isAdmin) this.columns.push(this.deleteColumn);
  }

  async componentDidMount() {
    const listName = this.props.params.listName;
    const user = auth.getCurrentUser();
    this.setState({ user: user, currentPage: 1 });
    const trackList = await audio.fetchTrackList(listName);
    await audio.fetchTracks(trackList, this.getPageData, (r) => {
      console.log('here', r);
      this.setState(r);
    });
    console.log('Tracks component did mount done');
    this.setState({ recordings: trackList, loading: false });
  }

  handlePageChange = async (page: number) => {
    this.setState({ currentPage: page });
    audio.fetchTracks(this.state.recordings, this.getPageData, (r) => {
      this.setState(r);
    });
  };

  handleSort = async (sortColumn: SortColumn) => {
    this.setState({ sortColumn });
    audio.fetchTracks(this.state.recordings, this.getPageData, (r) => {
      this.setState(r);
    });
  };

  handleDelete = async (track: Track) => {
    console.log('Delete track');
    const originalTracks = this.state.recordings;
    const tracks = originalTracks.filter((m) => m._id !== track._id);
    this.setState({ recordings: tracks });
    try {
      await audio.deleteTrack(track);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error('This track has already been deleted.');
      this.setState({ recordings: originalTracks });
      // throw ex;
    }
    // The below is needed as new tracks will come into view...
    audio.fetchTracks(this.state.recordings, this.getPageData, (r) => {
      this.setState(r);
    });
  };

  getPageData = (allData: Track[]) => {
    const { sortColumn, user, currentPage, pageSize } = this.state;
    if (!allData || !user) return { totalCount: 0, data: null };
    const filtered = user.isAdmin
      ? allData
      : allData.filter((r) => r.metadata.owner === user.email);

    const sorted = filtered.sort((a, b) => {
      if (sortColumn.order === 'asc') {
        return a[sortColumn.path] > b[sortColumn.path] ? 1 : -1;
      } else {
        return a[sortColumn.path] < b[sortColumn.path] ? 1 : -1;
      }
    });

    const page = paginate(sorted, currentPage, pageSize);
    return { totalCount: filtered.length, data: page };
  };

  render() {
    const { recordings, sortColumn, pageSize, currentPage, loading } =
      this.state;
    console.log('Tracks render');
    if (loading) {
      return <div>Loading...</div>; // Or a spinner component
    }
    console.log(recordings);
    const { totalCount, data: recording_list } = this.getPageData(recordings);
    return (
      <Container>
        <h1>{this.props.params.listName}</h1>
        <Table
          columns={this.columns}
          data={recording_list ? recording_list : []}
          sortColumn={sortColumn}
          onSort={this.handleSort}
        />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
      </Container>
    );
  }
}

export default Tracks;
