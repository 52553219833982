import http from './httpService';

const apiEndpoint = '/users';

interface User {
  username: string;
  password: string;
  name: string;
}

interface ApiResponse {
  data: any; // Replace `any` with the actual response type if known
}

export function register(user: User): Promise<ApiResponse> {
  return http.post(`${apiEndpoint}/signup`, {
    email: user.username,
    password: user.password,
    name: user.name,
  });
}

export function forgotPassword(
  user: Partial<Pick<User, 'username'>>
): Promise<ApiResponse> {
  return http.post(`${apiEndpoint}/forgot`, {
    email: user.username,
  });
}

export function getUsers(): Promise<ApiResponse> {
  console.log('in getUsers');
  return http.get(`${apiEndpoint}/users`);
}

export function resetPassword(
  password: string,
  id: string
): Promise<ApiResponse> {
  return http.patch(`${apiEndpoint}/reset`, {
    password: password,
    id: id,
  });
}

export default {
  getUsers,
};
