import React, { useState, useEffect } from 'react';
import { playAudioFile } from './audio';

const SetupFlow = ({ onComplete, children, completedAudio }) => {
  const [stage, setStage] = useState(0);
  const [skips, setSkips] = useState(0);
  const childrenArray = React.Children.toArray(children);
  const numStages = children ? children.length : 0;

  useEffect(() => {
    // Reset stage when component mounts
    setStage(0);
  }, []);

  useEffect(() => {
    if (stage >= numStages) {
      onComplete();
    }
  }, [stage, numStages, onComplete]);

  useEffect(() => {
    let count = 0;
    children.map((c) => (count += !!c.type.skipCount));
    setSkips(count);
  }, [children]);

  console.log('stage', stage);
  if (stage >= numStages) {
    return null;
  }

  return React.cloneElement(childrenArray[stage], {
    success: async () => {
      playAudioFile(completedAudio);
      setStage(stage + 1);
    },
    next: () => {
      setStage(stage + 1);
    },
    back: () => {
      setStage(stage - 1);
    },
    flowTotal: childrenArray.length - skips,
    skipCount: () => {
      setSkips(skips + 1);
    },
    flowStage: stage - skips,
  });
};

export default SetupFlow;
