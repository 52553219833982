import http from './httpService';
import { jwtDecode } from 'jwt-decode';

const apiEndpoint = '/auth';
const tokenKey = 'token';

const jwt = getJwt();
if (jwt) {
  http.setJwt(jwt);
}

export async function login(email: string, password: string): Promise<void> {
  const { data: jwt } = await http.post(apiEndpoint, { email, password });
  localStorage.setItem(tokenKey, jwt);
  http.setJwt(jwt);
}

export function loginWithJwt(jwt: string): void {
  localStorage.setItem(tokenKey, jwt);
}

export function logout(): void {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser(): any {
  try {
    const jwt = localStorage.getItem(tokenKey);
    if (jwt) {
      return jwtDecode(jwt);
    }
    return null;
  } catch (ex) {
    return null;
  }
}

export function getJwt(): string | null {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
};
