import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';

import ReactHowler from 'react-howler';

class Play extends Component {
  state = {
    isPlaying: false,
  };

  handlePlay = () => {
    this.setState((prevState) => ({
      isPlaying: !prevState.isPlaying,
    }));

    console.log('Button clicked');
  };

  handleFinish = () => {
    console.log('handling finish');
    this.setState({ isPlaying: false });
  };

  render() {
    const { url } = this.props;
    console.log('url', url);
    return (
      <React.Fragment>
        <ReactHowler
          playing={this.state.isPlaying}
          onEnd={this.handleFinish}
          preload={true}
          src={url}
          format={['mp3', 'aac']}
        />{' '}
        <Button onClick={this.handlePlay} disabled={this.state.isPlaying}>
          {'Play'}
        </Button>
      </React.Fragment>
    );
  }
}

export default Play;
