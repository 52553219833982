import React from 'react';
import Joi from 'joi-browser';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import auth from '../services/authService';
import audio from '../services/audioService';
import Form from './common/form';
import { RecordRTCPromisesHandler } from 'recordrtc';

interface RecorderState {
  blob: string;
  user: any; // Replace `any` with the appropriate type if known
  data: {
    name: string;
  };
  errors: Record<string, string>;
  isRecording: boolean;
  isBlocked: boolean;
}

class Recorder extends Form {
  constructor(props: unknown) {
    super(props);
    const user = auth.getCurrentUser();

    this.state = {
      isRecording: false,
      isBlocked: false,
      user: user,
      data: { name: '' },
      errors: {},
      blob: '',
    };
  }

  schema = {
    name: Joi.string().required().label('Name'),
  };

  doSubmit = async () => {
    try {
      console.log(this.state);
      audio.saveTrack(
        'stimuli',
        this.state.user,
        this.state.data.name,
        this.state.blob
      );
      this.state.blob = '';
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        this.setState({ errors });
      }
    }
  };

  start = () => {
    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      this.recorder.startRecording();
      this.setState({ isRecording: true });
    }
  };

  stop = async () => {
    await this.recorder.stopRecording();
    this.recorder.stopRecording();
    const blob = await this.recorder.getBlob();
    const blobURL = URL.createObjectURL(blob);
    this.setState({ blobURL, blob, isRecording: false });
  };

  async componentDidMount() {
    const stream = await navigator.mediaDevices.getUserMedia(
      { audio: true },
      () => {
        console.log('Permission Granted');
        this.setState({ isBlocked: false });
      },
      () => {
        console.log('Permission Denied');
        this.setState({ isBlocked: true });
      }
    );
    this.recorder = new RecordRTCPromisesHandler(stream, {
      type: 'audio',
    });
  }

  validate() {
    console.log('in recorder validate');
    let errors = super.validate();
    if (!this.state.blob) errors = { name: 'No audio recorded' };
    console.log('errors', errors);
    return errors;
  }

  render() {
    return (
      <Container>
        <Row>
          <ButtonToolbar>
            <Button
              variant="danger"
              className="m-2"
              onClick={this.start}
              disabled={this.state.isRecording}
            >
              Record
            </Button>
            <Button
              variant="primary"
              className="m-2"
              onClick={this.stop}
              disabled={!this.state.isRecording}
            >
              Stop
            </Button>
          </ButtonToolbar>
        </Row>
        <Row>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio className="m-2" src={this.state.blobURL} controls="controls" />
        </Row>

        <form onSubmit={this.handleSubmit}>
          {this.renderInput('name', 'Name', 'name')}

          {this.renderButton('Upload')}
        </form>
      </Container>
    );
  }
}

export default Recorder;
