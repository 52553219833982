import { useLocalStorage } from './useLocalStorage';

function generateVolumeKey(user) {
  return `preferredVolume:${user.email}`;
}

const DEFAULT_VOLUME = 50;

// Helper hook for getting volume preferences
export function useVolume(user) {
  // (https://usehooks.com/useLocalStorage/)
  return useLocalStorage(generateVolumeKey(user), DEFAULT_VOLUME);
}
