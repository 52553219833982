import React, { useState, useRef, useEffect } from 'react';
import { useElapsedTime } from '../hooks';
import { playAudioFile } from '../audio';
import { RecordRTCPromisesHandler } from 'recordrtc';
import { useVolume } from '../../hooks/useVolume';
import authService from '../../../services/authService';
import ReactHowler from 'react-howler';

/*
const computeMean = (array) => {
  if (!array) return 0;
  return Array.from(array).reduce((a, b) => a + b, 0) / array.length;
};
*/

/**
 * Component will show audio is being played back
 */

/*
const AudioPlayback = ({ blobUrl, PlayingComponent, onComplete }) => {
  const [playing, setPlaying] = useState(true);
  const [audioFrame, setAudioFrame] = useState(null);

  useEffect(() => {
    (async () => {
      // Loaded
      const audio = await playAudioFile(blobUrl, false);
      // Wait for playback finish and update progress
      await audio.waitForComplete((progress) => {
        setAudioFrame(progress.t);
      });
      setPlaying(false);
      onComplete();
    })();
  }, [blobUrl]);

  if (playing) {
    return <PlayingComponent audioFrame={audioFrame} />;
  } else {
    return null;
  }
};
*/

const MicrophoneRecorder = ({
  stream,
  beepDelay,
  beepAudio,
  onComplete = () => {},
  recordingLength,
  WaitingComponent,
  RecordingComponent,
  CompleteComponent = React.Fragment,
}) => {
  // 'waiting', 'recording', 'complete'
  const [stage, setStage] = useState('waiting');
  const elapsedTime = useElapsedTime(1000 / 60);
  const recordStartRef = useRef(null);

  useEffect(() => {
    if (stage === 'waiting') {
      const timeout = setTimeout(async () => {
        const audio = await playAudioFile(beepAudio);
        await audio.waitForComplete();
        setStage('recording');
      }, beepDelay);
      return () => {
        clearTimeout(timeout);
      };
    } else if (stage === 'recording') {
      const recorder = new RecordRTCPromisesHandler(stream);
      recorder.startRecording();

      let timeout = setTimeout(async () => {
        // Need to await otherwise blob is null
        await recorder.stopRecording();

        const blob = await recorder.getBlob();
        const blobUrl = URL.createObjectURL(blob);

        timeout = null;
        setStage('complete');
        onComplete(blobUrl);
      }, recordingLength);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [stage, beepAudio, beepDelay, stream, recordingLength, onComplete]);

  useEffect(() => {
    if (stage === 'recording' && recordStartRef.current == null) {
      recordStartRef.current = elapsedTime;
    }
  }, [stage, elapsedTime]);

  if (stage === 'waiting') {
    return <WaitingComponent timeRemaining={beepDelay - elapsedTime} />;
  } else if (stage === 'recording') {
    return <RecordingComponent time={elapsedTime - recordStartRef.current} />;
  } else if (stage === 'complete') {
    return <CompleteComponent />;
  } else {
    return null;
  }
};

export const MicrophoneCheck = (props) => {
  const { audio, recordingLength, timeBeforeDeep = 1000 } = props;
  const [volume] = useVolume(authService.getCurrentUser());

  // null, 'recording', 'playing'
  const [stage, setStage] = useState(null);

  // Data recorded from microphone
  const [blobUrl, setBlobUrl] = useState(null);

  // Result from getUserMedia
  const [audioStream, setAudioStream] = useState(null);

  const canRecord = audioStream != null;

  useEffect(() => {
    (async () => {
      try {
        setAudioStream(
          await navigator.mediaDevices.getUserMedia({ audio: true })
        );
      } catch {
        // TODO Handle this case
        alert('Failed to get audio stream');
      }
    })();
  }, []);

  return (
    <div>
      <h1>Microphone Check</h1>
      <p>We will next check that your microphone is working. </p>
      <p>
        You will be asked to speak after a beep and then your voice will be
        played back.
      </p>

      {canRecord ? (
        (stage == null && (
          <button
            onClick={() => setStage('recording')}
            className="btn btn-primary"
          >
            Test Microphone
          </button>
        )) ||
        (stage === 'recording' && (
          <MicrophoneRecorder
            // Configuration
            beepAudio={audio}
            stream={audioStream}
            beepDelay={timeBeforeDeep}
            recordingLength={recordingLength}
            // Callback
            onComplete={(blobUrl) => {
              setBlobUrl(blobUrl);
              setStage('playback');
            }}
            // Components to render at different stages
            WaitingComponent={({ timeRemaining }) => (
              <>
                <progress
                  max={100}
                  value={String((timeRemaining / timeBeforeDeep) * 100)}
                ></progress>
                <br />
                <button disabled className="btn btn-primary">
                  Start talking after you hear the tone
                </button>
              </>
            )}
            RecordingComponent={({ time }) => (
              <>
                <progress
                  max={100}
                  value={String((time / recordingLength) * 100)}
                ></progress>
                <br />
                <button disabled className="btn btn-primary">
                  Speak now
                </button>
              </>
            )}
          />
        )) ||
        (stage === 'playback' && (
          <>
            <ReactHowler
              volume={volume / 100}
              preload={true}
              loop={true}
              format={['mp3', 'aac']}
              src={blobUrl}
            />
            <button disabled className="btn btn-primary">
              Can you hear?
            </button>
            <button
              className="btn btn-default"
              onClick={() => setStage('recording')}
            >
              No
            </button>
            <button className="btn btn-primary" onClick={() => props.success()}>
              Yes
            </button>
          </>
        ))
      ) : (
        <div>Waiting for audio API</div>
      )}
      <br />
      {/* {passed && <BottomBar {...props}>Next</BottomBar>} */}
    </div>
  );
};

export default MicrophoneCheck;
