import React from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

const Select = ({ name, label, options, error, ...rest }) => {
  return (
    <Form.Group>
      <Form.Label htmlFor={name}>{label}</Form.Label>
      <Form.Control name={name} id={name} {...rest} as="select">
        <option value="" />
        {options.map((option) => (
          <option key={option._id} value={option._id}>
            {option.name}
          </option>
        ))}
      </Form.Control>
      {error && <div className="alert alert-danger">{error}</div>}
    </Form.Group>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Select;
