import React from 'react';
import Joi from 'joi-browser';
import Form from './common/form';
import * as userService from '../services/userService';
import Container from 'react-bootstrap/Container';

interface ForgotPasswordState {
  data: {
    username: string;
  };
  sentEmail: boolean;
  errors: {
    username?: string;
  };
}

class ForgotPassword extends Form {
  state: ForgotPasswordState = {
    data: { username: '' },
    sentEmail: false,
    errors: {},
  };

  schema: Record<string, Joi.Schema> = {
    username: Joi.string().required().email().label('Username'),
  };

  doSubmit = async (): Promise<void> => {
    try {
      console.log('in doSubmit forgot');
      await userService.forgotPassword(this.state.data);
      this.setState({ sentEmail: true });
    } catch (ex: unknown) {
      if (ex instanceof Error) {
        const errors = { ...this.state.errors };
        errors.username = ex.message;
        this.setState({ errors });
      }
    }
  };

  render(): React.JSX.Element {
    if (this.state.sentEmail) {
      return <span>An email with reset instructions is on its way.</span>;
    } else {
      return (
        <Container>
          <h1>Forgot Password</h1>
          <form onSubmit={this.handleSubmit}>
            {this.renderInput('username', 'Enter email address')}
            {this.renderButton('Submit')}
          </form>
        </Container>
      );
    }
  }
}

export default ForgotPassword;
