import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Nav from 'react-bootstrap/Nav';

interface User {
  isAdmin: boolean;
  name: string;
}
interface NavBarProps {
  user: User | null;
}

const NavBar: React.FC<NavBarProps> = ({ user }) => {
  const isAdmin = user?.isAdmin;
  if (!user) {
    return <div></div>;
  }
  if (user && !isAdmin) {
    return (
      <Navbar bg="light" expand="sm" className="px-3 mb-4">
        <Navbar.Brand href="/">Clarity</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/logout">[Logout]</Nav.Link>
            <Navbar.Text>
              Signed in as: <a href="/profile">{user.name}</a>
            </Navbar.Text>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
  return (
    <Navbar bg="light" expand="sm" className="px-3 mb-4">
      <Navbar.Brand href="/">Clarity</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
          <Nav.Link href="/about">About</Nav.Link>

          <Nav.Link disabled={!user} href="/runTest">
            Listen
          </Nav.Link>

          <NavDropdown
            title="Upload"
            disabled={!isAdmin}
            id="collapsible-nav-dropdown"
          >
            <NavDropdown.Item href="/upload">Stimuli</NavDropdown.Item>
            <NavDropdown.Item href="/uploadTest">
              Listening Test
            </NavDropdown.Item>
            <NavDropdown.Item href="/record">Record</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            disabled={!user}
            title="Review"
            id="collapsible-nav-dropdown"
          >
            <NavDropdown.Item disabled={!isAdmin} href="/review/stimuli">
              Stimuli
            </NavDropdown.Item>
            <NavDropdown.Item href="/review/response">
              Responses
            </NavDropdown.Item>
            <NavDropdown.Item href="/review/tests">Tests</NavDropdown.Item>
          </NavDropdown>

          {isAdmin && (
            <NavDropdown title="Admin" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="/users">Manage Users</NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
        <Nav className="ml-auto">
          {!user && (
            <React.Fragment>
              <Nav.Link href="/register">Register</Nav.Link>
              <Nav.Link href="/login">Login</Nav.Link>
            </React.Fragment>
          )}
          {user && (
            <Nav className="justify-content-end">
              <Nav.Link href="/logout">Logout</Nav.Link>

              <Navbar.Text>
                Signed in as: <a href="/profile">{user.name}</a>
              </Navbar.Text>
            </Nav>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
