import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Table from './common/table';
import { getTestRecordsForUser } from '../services/testService';
import user from '../services/userService';

interface SortColumn {
  path: string;
  order: 'asc' | 'desc';
}

interface User {
  email: string;
  ntests: number;
  ncomplete: number;
}

interface Test {
  nStimuli: number;
  nComplete: number;
}

interface ManageUsersState {
  users: User[];
  sortColumn: SortColumn;
}

class ManageUsers extends Component {
  state: ManageUsersState = {
    users: [],
    sortColumn: { path: 'name', order: 'asc' },
  };

  columns = [
    { path: 'email', label: 'Email' },
    { path: 'ntests', label: '# Tests' },
    { path: 'ncomplete', label: '# Complete' },
  ];

  handleSort = (sortColumn: SortColumn) => {
    this.setState({ sortColumn });
  };

  async componentDidMount() {
    const users = await user.getUsers();
    this.setState({ users: users.data });
    for (const user of users.data) {
      // For each user retrieve tests and count how many are complete
      const tests = await getTestRecordsForUser(user.email);
      const completeTests = tests?.filter(
        (t: Test) => t.nComplete === t.nStimuli
      );
      user.ntests = tests.length;
      user.ncomplete = completeTests.length;
    }
    this.setState({ users: users.data });
  }

  render() {
    const { sortColumn, users } = this.state;

    // Sort the users based on the sortColumn

    const sortedUsers = users.slice().sort((a, b) => {
      const valueA = a[sortColumn.path];
      const valueB = b[sortColumn.path];

      if (sortColumn.order === 'asc') {
        return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      } else {
        return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
      }
    });

    return (
      <Container>
        <h1>User Progress</h1>
        <Table
          columns={this.columns}
          data={sortedUsers} // ? users : []}
          sortColumn={sortColumn}
          onSort={this.handleSort}
        />
      </Container>
    );
  }
}

export default ManageUsers;
