import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import auth from '../services/authService';
import { getMyTests, deleteTest } from '../services/testService';

import Table from './common/table';
import Pagination from './common/pagination';
import { paginate } from '../utils/paginate';

interface Test {
  _id: string;
  name: string;
  subject: string;
  stimuli: string;
  nComplete: number;
}

interface TestTableState {
  tests: string;
  user: string;
  sortColumn: { path: string; order: 'asc' | 'desc' };
  currentPage: number;
  pageSize: number;
}

interface Column {
  path?: string;
  label?: string;
  key?: string;
  content?: (__test: Test) => React.ReactNode;
}

class TestTable extends Component<unknown, TestTableState> {
  state: TestTableState = {
    tests: '',
    user: '',
    sortColumn: { path: 'name', order: 'asc' },
    currentPage: 1,
    pageSize: 6,
  };

  columns: Column[] = [
    {
      path: 'name',
      label: 'Test name',
    },
    {
      path: 'subject',
      label: 'Subject',
    },
    {
      key: 'stimuli',
      label: 'stimuli',
      content: (t) => {
        return <div>{t.stimuli.length}</div>;
      },
    },
    {
      key: 'complete',
      label: 'complete',
      content: (t) => {
        return (
          <div>
            {t.nComplete} / {t.stimuli.length}
          </div>
        );
      },
    },
  ];

  deleteColumn = {
    key: 'delete',
    content: (track: any) => (
      <Button
        variant="danger"
        onClick={() => {
          this.handleDelete(track);
        }}
      >
        Delete
      </Button>
    ),
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();

    if (user?.isAdmin) this.columns.push(this.deleteColumn);
  }

  async componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });

    const testList = await getMyTests();
    this.setState({ tests: testList });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleDelete = async (test) => {
    console.log('Delete test');
    const originalTests = this.state.tests;
    const tests = originalTests.filter((m) => m._id !== test._id);
    this.setState({ tests });
    try {
      await deleteTest(test.name);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error('This test has already been deleted.');
      this.setState({ tests: originalTests });
      // throw ex;
    }
  };

  getPageData = (allData: any) => {
    const { sortColumn, currentPage, pageSize } = this.state;
    if (!allData) return { totalCount: 0, data: null };

    const sorted = allData.slice().sort((a, b) => {
      const aValue = a[sortColumn.path];
      const bValue = b[sortColumn.path];

      if (aValue < bValue) return sortColumn.order === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortColumn.order === 'asc' ? 1 : -1;
      return 0;
    });

    const page = paginate(sorted, currentPage, pageSize);
    return { totalCount: allData.length, data: page };
  };

  render(): React.JSX.Element {
    const { tests, sortColumn, pageSize, currentPage } = this.state;

    const { totalCount, data: testList } = this.getPageData(tests);
    return (
      <Container>
        <h1>{this.props.params.listName}</h1>
        <Table
          columns={this.columns}
          data={testList ? testList : []}
          sortColumn={sortColumn}
          onSort={this.handleSort}
        />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
      </Container>
    );
  }
}

export default TestTable;
